











import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { eventPlugin } from "./plugins/eventPlugin/plugin";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    folder: {
      type: String,
      required: false,
      default: "",
    },
    variablesOff: {
      type: Boolean,
      required: false,
      default: "",
    },
    editorHeight: {
      type: Number,
      required: false,
      default: 500,
    },
  },

  setup(props, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const imageUpload = (
      blobInfo: any,
      success: any,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      failure: any
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let imageId = "";
      const formData = new FormData();

      formData.append("image", blobInfo.blob());

      axiosInstance
        .post(`image/${props.folder}`, formData)
        .then(({ data: { id } }) => {
          imageId = id;
        })
        .catch((e) => console.log(e));

      const img = "data:image/jpeg;base64," + blobInfo.base64();
      success(img);
    };
    // TinyMCE configuration

    const state = reactive({
      editor: {
        apiKey: "hmwa8xl4jfodlotfetixl613xzjmf53vrtp434v7za0kye7a",
        init: {
          height: props.editorHeight,
          language: "pl",
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap anchor image",
            "eventButton preview code",
          ],
          toolbar1:
            "undo redo | formatselect | fontselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent, code | removeformat image preview",
          toolbar2: `${props.variablesOff ? "" : "eventButton"}`,
          images_upload_handler: imageUpload,
          automatic_uploads: false,
          setup() {
            const tinymce = (window as any).tinymce;
            tinymce.PluginManager.add("eventButton", eventPlugin);
          },
        },
      },
    });

    return { state };
  },
});
