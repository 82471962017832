// TinyMCE plugin with event data

const eventPlugin = function(editor: any) {
  editor.ui.registry.addMenuButton('eventButton', {
    text: 'Wydarzenie',
    fetch: function (callback: any) {
      const items = [
        {
          type: 'menuitem',
          text: 'Nazwa wydarzenia',
          onAction: function () {
            editor.insertContent('{{event.name}}');
          }
        },
        {
          type: 'menuitem',
          text: 'Data rozpoczęcia',
          onAction: function () {
            editor.insertContent('{{event.startDate}}');
          }
        },
        {
          type: 'menuitem',
          text: 'Data zakończenia',
          onAction: function () {
            editor.insertContent('{{event.endDate}}');
          }
        },
        {
          type: 'menuitem',
          text: 'Miejsce wydarzenia',
          onAction: function () {
            editor.insertContent('{{event.location.name}}');
          }
        },
        {
          type: 'menuitem',
          text: 'Miasto wydarzenia',
          onAction: function () {
            editor.insertContent('{{event.location.city}}');
          }
        },
        {
          type: 'menuitem',
          text: 'Strona www',
          onAction: function () {
            editor.insertContent('{{event.website}}');
          }
        }
      ];

      callback(items);
    }
  });
}

export { eventPlugin };